@use '../abstract' as *;

#footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 30px;
  background-color: $accent-color;

  span {
    font-size: 16px;
    color: white;
    text-align: center;
  }
}
