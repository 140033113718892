body {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
  direction: rtl;
  overflow-x: hidden !important;
  font-family: IRANsans, sans-serif !important;
}

ul {
  list-style: none !important;
  margin: 0 !important;
}

a {
  text-decoration: none !important;
}
