@use '../abstract' as *;

#about-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-primary;

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 10px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  .about-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 35px;

    div {
      flex: 1 1 550px;
      border-radius: 15px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }

    p {
      flex: 1 1 400px;
      font-size: 20px;
      text-align: justify;
      color: $accent-color;
      line-height: 1.7;
      z-index: 5;

      @media (max-width: 800px) {
        font-size: 16px;
      }

      a {
        font-weight: bold;
        margin-right: 10px;
        font-size: 25px;
        color: $primary-color;
        cursor: pointer;
        text-decoration: underline !important;

        @media (max-width: 800px) {
          font-size: 18px;
        }
      }
    }
  }
}

#services {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-secondary;

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 10px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }

  #service-container {
    display: flex !important;
    gap: 15px;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
}

#customers {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 25px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
}

#experiences {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-secondary;
  margin-top: 60px;

  .experience-container {
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    .group-experience {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      z-index: 10 !important;
      p {
        font-size: 18px;
        text-align: center;
        color: white;
        padding-bottom: 0;
        padding-top: 25px;
        line-height: 1.8;

        @media (max-width: 700px) {
          text-align: justify;
        }
      }
      .items {
        padding: 60px 0;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        z-index: 10;
        gap: 25px;
      }
    }

    .overlay {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-color: rgba(137, 43, 226, 0.74);
    }
  }

  h6 {
    font-size: 35px;
    color: white;
    margin-bottom: 25px;
    border-bottom: 2px solid white;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
}

#certifications {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;

  #certification-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: space-around;
  }

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 25px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
}

#employees {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-secondary;

  #employee-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;
  }

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 25px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
}

#contact-us {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $bg-primary;

  .contact-container {
    display: flex;
    // align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 25px;
  }

  h6 {
    font-size: 35px;
    color: $accent-color;
    margin-bottom: 25px;
    border-bottom: 2px solid $accent-color;
    font-weight: bold;
    padding: 15px;

    @media (max-width: 800px) {
      font-size: 22px;
    }
  }
}
