@use '../../abstract/' as *;

.service-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  box-shadow: 0px 0px 20px -15px rgba(87, 63, 87, 1);
  border-radius: 10px;
  width: 100%;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  transition: all 1s ease !important;
  flex: 1 1 250px;
  cursor: pointer;

  &:hover {
    background-color: $bg-accent;

    span {
      color: white;
    }
  }

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
  }

  span {
    font-size: 18px;
    margin: 15px 0;
    text-align: center;
  }
}
