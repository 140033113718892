@use '../abstract' as *;

.btn {
  outline: none !important;
  border: none !important;

  &-primary {
    border-radius: 5px;
    padding: 5px 25px;
    background-color: $primary-color;
    color: white;
    font-size: 18px;
    margin-top: 20px;
    border: 1px solid transparent;
    padding-bottom: 7px;
    transition: all 0.5s ease;
    width: fit-content;

    @media (max-width: 800px) {
      font-size: 16px;
    }

    &:hover {
      background-color: white;
      color: $primary-color;
      border: 1px solid $primary-color;
    }
  }
}
