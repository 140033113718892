@use '../../abstract' as *;

#carousel {
  height: calc(100vh - 110px) !important;

  @media (max-width: 800px) {
    height: 75vh !important;
  }

  @media (max-width: 350px) {
    height: 85vh !important;
  }

  .carousel-inner {
    height: calc(100vh - 110px) !important;

    @media (max-width: 800px) {
      height: 75vh !important;
    }

    @media (max-width: 350px) {
      height: 85vh !important;
    }

    .carousel-item {
      height: 100%;
      position: relative;

      img {
        width: 100%;

        @media (max-width: 800px) {
          object-fit: cover;
        }
      }

      div {
        display: flex;
        flex-direction: column;
        padding: 15px 55px;
        border-radius: 15px;
        background-color: $bg-secondary;
        position: absolute;
        top: 50%;
        left: 10%;
        max-width: 600px;
        transform: translateY(-50%);

        @media (max-width: 800px) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 10px;
          width: 80%;
        }

        @media (max-width: 700px) {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 10px;
          width: 90%;
        }

        h2 {
          font-size: 35px;
          color: $secondary-color;
          margin-bottom: 15px;
          font-weight: bold;

          @media (max-width: 800px) {
            font-size: 22px;
          }

          @media (max-width: 500px) {
            font-size: 18px;
          }
        }
        p {
          font-size: 18px;
          color: $secondary-color;
          margin-bottom: 10px;
          overflow-wrap: break-word;
          text-align: justify;

          @media (max-width: 800px) {
            font-size: 16px;
          }

          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
        ul {
          width: 100%;
          padding: 0 !important;

          li {
            margin-bottom: 5px;
            font-size: 18px;
            color: $secondary-color;

            @media (max-width: 800px) {
              font-size: 16px;
            }

            @media (max-width: 500px) {
              font-size: 14px;
            }

            img {
              width: 25px;
              height: 25px;
              margin-left: 5px;

              @media (max-width: 800px) {
                width: 20px;
                height: 20px;
              }

              @media (max-width: 500px) {
                width: 15px;
                height: 15px;
              }
            }
          }
        }
      }

      img {
        height: 100%;
      }
    }
  }
}
