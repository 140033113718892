@use '../abstract' as *;

.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $primary-color;
  padding: 35px;
  margin-bottom: 25px;
  flex-wrap: wrap;
  gap: 10px;

  @media (max-width: 1150px) {
    justify-content: center;
  }

  @media (max-width: 600px) {
    padding: 10px;
  }

  #return-home {
    cursor: pointer;
  }

  h2 {
    font-size: 35px;
    color: white;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 20px !important;
    }
  }

  span {
    font-size: 16px;
    padding: 0 5px;
    color: white;
    text-align: center;

    @media (max-width: 600px) {
      font-size: 12px !important;
      padding: 0 1px;
    }
  }
}
.main-logo {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 70vh !important;
  width: 100%;
  border-radius: 15px;

  @media (max-width: 800px) {
    height: 50vh !important;
    background-size: contain;
    border-radius: 15px !important;
    padding: 0 !important;
  }

  @media (max-width: 600px) {
    height: 20vh !important;
    background-size: contain;
    border-radius: 15px !important;
    padding: 0 !important;
  }
}

.description {
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 25px;
    color: $accent-color;
    font-weight: bold;
    text-align: center;
    margin-bottom: 25px !important;
  }
  p {
    font-size: 16px;
    color: $accent-color;
    text-align: justify !important;
    margin-bottom: 25px !important;
  }

  .features {
    display: flex;
    flex-direction: column;
    padding: 0 !important;

    .main {
      font-size: 18px;
      font-weight: bold;
      color: $accent-color;
      padding: 7px 0;
      text-align: justify;
    }

    .subs {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px !important;

      @media (max-width: 600px) {
        padding: 0 !important;
      }

      li {
        padding: 10px 0;
        color: $accent-color;
        text-align: justify;
      }
    }
  }
}
