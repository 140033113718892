@use '../../abstract' as *;

.info {
  display: flex;
  flex-direction: column;
  flex: 1 1 450px;

  .address {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    border-bottom: 1px dashed $accent-color;

    & p {
      font-size: 16px;
      color: $accent-color;

      span {
        margin-left: 5px;
        font-weight: bold;
      }
    }
  }
  .extra-info {
    // margin-bottom: 10px;

    display: flex;
    align-items: center;
    font-size: 16px;
    color: $accent-color;

    span {
      margin-left: 5px;
      font-weight: bold;
    }
  }
}
