@font-face {
  font-family: IRANsans;
  src: url('../assets/fonts/ttf/IRANSansWeb.ttf');
  font-style: normal;
}

@font-face {
  font-family: IRANsans;
  src: url('../assets/fonts/ttf/IRANSansWebBold.ttf');
  font-weight: bold;
}
