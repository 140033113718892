@use '../../abstract' as *;

.employee-item {
  display: flex;
  flex-direction: column;
  flex: 1 1 250px;
  border-radius: 15px;
  width: 100%;
  box-shadow: 0px 13px 40px -41px rgba(87, 63, 87, 1);
  align-items: center;
  max-width: 250px;
  transition: all 0.6s ease;
  cursor: pointer;

  &:hover {
    transform: scale(1.1) !important;
  }

  .top-div {
    width: 100%;
    background-color: $primary-color;
    position: relative;
    height: 80px;
    border-radius: 15px 15px 0 0;
    margin-bottom: 45px;
  }

  .avatar-div {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    padding: 2px;
    background-color: $bg-secondary;
    width: 85px;
    height: 85px;

    .avatar {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .name {
    font-size: 19px;
    font-weight: bold;
    color: $accent-color;
    margin-bottom: 10px;
  }

  .specialty {
    font-size: 18px;
    color: $accent-color;
    margin-bottom: 10px;
  }

  .education {
    font-size: 16px;
    color: $accent-color;
  }

  .social-div {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    & div {
      height: 28px;
      width: 28px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px;
      border-radius: 50%;
      width: 100%;
      transition: all 0.5s ease-in;

      &:hover {
        transform: rotate(360deg);
      }

      &.linkedIn {
        background-color: #0c7ead;
      }

      &.facebook {
        background-color: #22559d;
      }

      &.email {
        background-color: #d52612;
      }

      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
