@use '../abstract' as *;

#navbar {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 15px;
  background-color: $secondary-color;
  position: sticky;
  top: 0;
  z-index: 1000;

  #hamburger,
  #close-navbar {
    display: none;
  }

  @media (max-width: 850px) {
    justify-content: center;

    #hamburger {
      width: 25px;
      height: 25px;
      object-fit: contain;
      cursor: pointer;
      display: block;
      position: absolute;
      left: 10%;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  img {
    width: 100px;
    height: 80px;
    object-fit: contain;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s ease;

    @media (max-width: 850px) {
      display: none;
      flex-direction: column;
      gap: 20px;
      position: fixed;
      right: 0;
      top: 0;
      height: 100vh;
      transform: translateX(300px);
      background-color: $secondary-color;

      #close-navbar {
        width: 35px;
        height: 35px;
        object-fit: contain;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(50%, 100%);
        cursor: pointer;
        display: block;
      }
    }

    li {
      padding: 5px 15px;
      a {
        color: white;
      }
    }
  }
}
