@import '../../../node_modules/bootstrap/scss/bootstrap.scss';

@media (max-width: 600px) {
  .carousel-control-next-icon {
    transform: translateX(15px);
  }

  .carousel-control-prev-icon {
    transform: translateX(-15px);
  }
}

@media (max-width: 300px) {
  .carousel-control-next-icon {
    transform: translateX(0);
  }

  .carousel-control-prev-icon {
    transform: translateX(0);
  }
}
