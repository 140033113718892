@use '../../abstract' as *;

.certification-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 150px;
  cursor: pointer;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-bottom: 10px;
  }

  span {
    font-size: 16px;
    color: $accent-color;
    text-align: center;
  }
}
