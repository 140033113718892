@import '../../../node_modules/swiper/swiper-bundle.min';

.swiper-slide {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 25px;
}
