.experience-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 250px;

  img {
    width: 65px;
    height: 65px;
    object-fit: contain;
    margin-bottom: 10px;

    @media (max-width: 800px) {
      width: 45px;
      width: 45px;
    }
  }

  span {
    color: white;
    font-size: 20px;
    font-weight: bold;

    @media (max-width: 800px) {
      font-size: 16px;
    }
  }

  span.amount {
    font-size: 30px;
    color: white;
    margin-top: 10px;
    font-weight: bold;

    @media (max-width: 800px) {
      font-size: 20px;
    }
  }
}
